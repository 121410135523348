import logo from './logo-2.png'
import './App.css';
import { useState } from 'react';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const QuizStep = ({ current, total, answers, question, onItemSelect }) => {
  return (
    <div>
      <h3>
        ({current}/{total}) {question.value}
      </h3>

      <div className="radio-container">
        {answers.map((answer, index) => {
          return (
            <div key={index} className="answer-item">
              <button id={answer} onClick={() => onItemSelect(answer)}>
                {answer}
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Answer = ({ answer, correct }) => {
  return (
    <div
      style={{
        color: correct ? 'green' : 'red',
      }}>
      <h1>{correct ? 'Correct!' : 'Incorrect'}</h1>
      <h2>{answer}</h2>
    </div>
  )
}

const Results = ({ selectedAnswers, quiz }) => {
  return (
    <div className="results">
      {selectedAnswers &&
        Object.values(selectedAnswers).map((answer, index) => (
          <pre className="result-answer" key={index}>
            <code>
              ({index + 1}) {quiz[index].question.value}
            </code>
            <code
              style={{
                color: answer.correct ? 'green' : 'red',
              }}>
              {answer.value}
            </code>
          </pre>
        ))}
    </div>
  )
}

const QuizMakerForm = ({ onSubmit, query, onQueryChange }) => {
  return (
    <form name="quiz-maker-form" className="quiz-form" onSubmit={onSubmit}>
      <code>
        <h3>Input some text for your quiz</h3>
      </code>
      <code style={{ fontSize: '1.3rem;' }}>
        Click <strong>Create</strong> for random quiz or write e.g. 'Europe capitals' or 'Worst movies of history'
      </code>
      <br />
      <br />
      <br />
      <div className="query-input">
        <input
          value={query}
          onChange={(e) => onQueryChange(e.target.value)}
          name="quiz-query"
          type="text"
          placeholder="Topic"
        />
        <button type="submit">
          <code>Create [enter]</code>
        </button>
      </div>
    </form>
  )
}

function App() {
  const [quiz, setQuiz] = useState()
  const [query, setQuery] = useState('')
  const [selectedAnswers, setSelectedAnswers] = useState()
  const [showAnswer, setShowAnswer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState()
  const [showResults, setShowResults] = useState(false)
  const [error, setError] = useState()

  const onOptionChange = async (value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [currentStep]: {
        value,
        correct: value === quiz[currentStep].question.answer,
      },
    })
    
    setShowAnswer(true)
    await wait(1300)
    setShowAnswer(false)
    
    if (currentStep === quiz.length - 1) {
      setCurrentStep(0)
      setShowResults(true)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleSubmit = async (e) => {
    // logEvent('submit_query', { query })
    e.preventDefault()
    await reset()
    setLoading(true)
    try {
      const results = await fetch(`${process.env.REACT_APP_QUIZ_SURF_API}/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.REACT_APP_QUIZ_SURF_API_KEY,
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ query }),
      })

      if (!results?.ok) {
        setError(`${results.status} ${results.statusText}`)
        return
      }

      const newQuiz = await results.json()
      setQuiz(newQuiz)
      setLoading(false)

      // logEvent('quiz_created', { quiz })
    } catch (error) {
      setError(error)
    }
  }

  const reset = (e) => {
    setCurrentStep(0)
    setQuiz()
    setSelectedAnswers()
    setShowAnswer(false)
    setShowResults(false)
    setLoading(false)
    setError()
    // logEvent('reset', { currentStep })
  }

  if (error) {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />

          <h2>
            <code>Create a quiz</code>
          </h2>

          <button onClick={reset}>Reset</button>
          <h3>Oops, something went wrong</h3>
          <p>{String(error)}</p>
        </header>
      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="reset-button">
          <code>Click logo to reset</code>
        </div>
        <div onClick={reset} className="logo-button">
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <h2>
          <code>{!quiz && 'Create a quiz'}</code>
        </h2>

        {!loading && !quiz && <QuizMakerForm query={query} onQueryChange={setQuery} onSubmit={handleSubmit} />}

        {loading && <p>Fighting against human domination...</p>}

        {showResults && <button onClick={(e) => {
          // logEvent('click', { action: 'play_again', quiz })
          handleSubmit(e)
        }}>PLAY AGAIN</button>}

        {quiz && !showAnswer && !showResults && (
          <div>
            <QuizStep
              {...quiz[currentStep]}
              current={currentStep + 1}
              total={quiz.length}
              onItemSelect={onOptionChange}
            />
          </div>
        )}

        {showAnswer && (
          <Answer
            answer={quiz[currentStep].question.answer}
            correct={quiz[currentStep].question.answer === selectedAnswers[currentStep].value}
          />
        )}

        {showResults && <Results selectedAnswers={selectedAnswers} quiz={quiz} />}
      </header>
    </div>
  )
}

export default App;
